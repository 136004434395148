export const i18n = (variables) => ({
  error: {
    401: 'Unauthorized Request: Please sign in and try again',
    404: `${variables.resource ? variables.resource : 'Resource'} not found`,
    teamAdd: 'User already existing',
    unavailable: 'Unable to contact the server',
    unableToGetStats: 'Unable to get statistics',
    apiError: 'An Error Occurred. Please contact your admin',
  },
  action: {
    copied: 'copied to clipboard.',
    drag: {
      multipleRedirects: `You are moving ${variables.nbRedirects} redirects`,
    },
  },
  endOfTrial: {
    trial: `Woohoo! You’ve completed your ${variables.business ? 'business' : ''} trial.`,
    revoked: 'Your coupons are no longer valid.',
    content:
      "We hope we've helped you get a better return on your campaigns. To continue using PixelMe please choose a plan that best fits your needs.",
    button: 'View paid plans',
  },
  head: {
    shortener: 'Shortener',
    analytics: 'Analytics',
    billing: 'Billing',
    settings: 'Account',
    faq: 'FAQ',
    onboarding: 'Onboarding',
    accounts: 'Accounts',
    asinAudit: 'ASIN Audit',
  },
  subCampaigns: {
    name: 'Sub folder name',
    title: 'Sub folder',
    add: {
      button: 'Create',
      title: 'Add a sub folder',
    },
    delete: {
      button: 'Delete',
      title: 'Delete a sub folder',
      help: 'All your redirects will be moved back under unclassified folder',
    },
    edit: {
      button: 'Save',
      title: 'Edit a sub folder',
    },
    addRedirects: {
      success: `${variables.nbRedirects} Redirect(s) successfully added to sub folder ${variables.subCampaign}, folder  ${variables.campaign}`,
    },
  },
  campaigns: {
    allCampaigns: 'My folders',
    allLinks: 'All links',
    name: 'Folder name',
    title: 'Folder',
    select: {
      title: 'Folder',
      placeholder: 'Select a folder',
    },
    add: {
      button: 'Create',
      title: 'Create a folder',
    },
    delete: {
      button: 'Delete',
      title: 'Delete a folder',
      help: 'All your redirects will be moved back under unclassified folder',
    },
    edit: {
      button: 'Save',
      title: 'Edit a folder',
    },
    addRedirects: {
      success: `${variables.nbRedirects} Redirect(s) successfully added to the folder ${variables.campaign}`,
    },
    subCampaigns: {
      select: {
        title: 'Sub folder',
        placeholder: 'Select a sub folder',
      },
    },
    keywords: {
      label: 'Keywords',
      add: 'Add a new keyword',
      bulkAdd: 'Bulk add keywords',
      bulkAddPlaceholder: 'Enter a keyword on each line',
      invalid: 'Invalid keywords',
    },
    matchType: {
      label: 'Match Type',
      learnMore: 'Learn More',
      broad: 'Broad Match',
      broadDescription:
        'Ads may show on searches that are related to your keyword, which can include searches that don’t contain the keyword terms. This helps you attract more visitors to your website, spend less time building keyword lists, and focus your spending on keywords that work. Broad match is the default match type that all your keywords are assigned, so that you don’t have to specify another match type (exact match, phrase match, or a negative match type).',
      exact: 'Exact Match',
      exactDescription:
        'Ads may show on searches that have the same meaning or same intent as the keyword. Of the 3 keyword matching options, exact match gives you the most control over who sees your ad, but reaches fewer searches than both phrase and broad match.',
      phrase: 'Phrase Match',
      phraseDescription:
        'Ads may show on searches that include the meaning of your keyword. The meaning of the keyword can be implied, and user searches can be a more specific form of the meaning. With phrase match, you can reach more searches than with exact match and fewer searches than with broad match, only showing your ads on the searches that include your product or service.',
    },
    adsProviderSuccess: `Your campaign has been uploaded and will be reviewed by ${variables.adsProviderType}.`,
  },
  campaignAutomations: {
    title: 'Campaign Automation Rules',
    shortTitle: 'Automation Rules',
    description:
      'Optimize your ad campaigns with automated keyword and bid management. Tailor your spend and performance criteria to align with strategic goals, ensuring your advertising efforts drive the best performance.',
    descriptionHighlight:
      'In the section below, you can override PixelMe’s default automation rules with your own custom rules by changing the status to either Enabled or Disabled.',
    tooltip: 'Automation Settings',
    options: {
      default: 'PixelMe Defaults',
      enabled: 'Enabled',
      disabled: 'Disabled',
    },
    keywordPauser: {
      title: 'Keyword Pauser',
      description:
        'Enable the Keyword Pauser to automatically pause keywords where ACOS is too high (can set the Maximum ACOS below once enabled).',
      defaultBehaviorText:
        'Selecting “PixelMe Defaults” will enable the keyword pauser using our default rules which is any keyword that is above 300% ACOS OR has 50 clicks and zero purchases will be paused.',
      maxAllowedAcosPerKw: {
        title: 'Maximum Allowable ACOS per Keyword',
        description:
          'Set the maximum ACOS percentage for acceptable keyword performance. If a keyword exceeds the maximum ACOS, then it will be automatically paused. For example, if the maximum Allowable ACOS is 150% and a keyword has an ACOS of 200%, it will be paused by the Keyword Pauser.',
      },
      reviewPausedKeywords: {
        title: 'Review Paused Keywords for Delayed Attribution',
        description:
          'Attribution data can be delayed by up to 14 days. Pixelme will automatically review previously paused keywords with the new updated attribution data. If a keyword is below the maximum Allowable ACOS, the keyword will be re-enabled.',
      },
      maxAdSpendPerKw: {
        title: 'Maximum Ad Spend Per Keyword',
        description:
          'If a keyword reaches this amount of Ad Spend with no sales, it will be paused. A good practice is to set this number to 2x the product price. This ensures that we have enough data for this keyword to confidently pause it. For example, if your product price is $30, you should aim to set this at $60.',
      },
      guaranteedActiveKwCount: {
        title: 'Guaranteed Number of Active Keywords',
        shortTitle: 'Guaranteed Number of Active Keywords',
        description:
          'In order to ensure that the campaign continues to spend, set the number of active keywords you’d want to keep active in this campaign no matter what. For example, if you set this number to 3, we will always keep at least 3 keywords active in this campaign and will not pause any more keywords. Some customers like to pause all poor-performing keywords, so they set this at zero, while other customers will typically choose a number from 1 to 5.',
      },
    },
    automatedBidding: {
      title: 'Automated Bidding',
      description:
        'With automated bidding, we automatically decrease and increase Keyword CPC Bids on Google to drive best performance for your campaign. With this automation enabled, it’ll take up to 2 weeks for our system to properly learn and optimize bids.',
      defaultBehaviorText:
        'Selecting “PixelMe Defaults” will enable the automated bidding rules which will dynamically choose the best bidding strategy for your campaign and adjust bids. This is the same behavior as selecting the “Enabled” option.',
    },
    negateKeywords: {
      title: 'Negate Keywords',
      description:
        'This automation is a one time automation per campaign that inserts a negative keyword list into the campaign using the phrase match type',
      defaultBehaviorText:
        'Selecting “Pixelme Defaults” will not do anything. It will not negate any keywords. Please select the “Enabled” option if you want Pixelme to insert our negative keyword list based on best practices.',
    },
    keywordHarvesting: {
      title: 'Keyword Harvesting',
      description:
        'This automation will identify high performing search terms and add them back into the campaign as exact match keywords. This improves the keyword targeting of the campaign. A high performing search term is one that is performing 30% better than the campaign over the past 45 days and has 3 or more conversions',
      defaultBehaviorText:
        'Selecting “Pixelme Defaults” will not do anything. It will not harvest any keywords. Please select an “Enabled” option to enable this automation rule.',
      strategy: {
        title: 'Keyword Harvesting Strategy',
        customAcos: {
          name: 'Custom ACOS',
          description:
            'allows you to set the ACOS target for keyword harvesting. If a search term ACOS is equal to or less than the target, it will be added into the campaign.',
        },
        default: {
          name: 'Default',
          description:
            'will enable the keyword harvester using the PixelMe default rules which is 30% better than the campaign performance over the last 45 days.',
        },
      },
      acosTarget: {
        title: 'ACOS Target',
        description: 'Target ACOS for a search term to have in order for it to be harvested and added to the campaign',
      },
    },
  },
  utm: {
    source: {
      select: {
        title: 'UTM Source',
        placeholder: 'Select a source',
      },
    },
    medium: {
      select: {
        title: 'UTM Medium',
        placeholder: 'Select a medium',
      },
    },
    campaign: {
      select: {
        title: 'UTM Campaign',
        placeholder: 'Select a campaign',
      },
    },
    term: {
      input: {
        title: 'UTM Term',
        placeholder: 'Paid keywords',
      },
    },
    content: {
      input: {
        title: 'UTM Content',
        placeholder: 'Differentiate ads',
      },
    },
  },
  redirectCreator: {
    title: 'Your tracked redirect',
    campaigns: {
      title: 'Added to',
    },
  },
  composer: {
    button: 'Create a link',
    create: {
      head: 'Create a link',
      cardAlert: 'A valid credit card is required to create links',
      cardAlertTooltip:
        'We only need a credit card to verify your account in order to create links. We will not charge your card. You will only be charged if you upgrade to a paid plan.',
    },
    ads: {
      title: 'Amazon Attribution',
      help: '',
      monthlyCost: 'Monthly ad cost',
      enabled: 'Monthly ad cost',
    },
    edit: {
      head: 'Edit a link',
    },
    redirect: {
      edit: {
        title: 'Save changes',
      },
      save: {
        title: 'Create your link',
      },
      title: 'Retargeting pixels',
      help: 'Add retargeting pixels & shorten your redirect',
    },
    utms: {
      title: 'UTMs parameters',
      help: 'Add UTMs parameters to your destination URL',
    },
    domains: {
      title: 'Branded domains',
      help: '',
    },
    description: {
      title: 'Description',
      help: '',
    },
    campaigns: {
      title: 'Folders & Tags',
      help: '',
    },
    rotatorRedirect: {
      title: 'URL rotator',
      help: '',
    },
    dynamicRedirect: {
      title: 'Dynamic Links',
      help: '',
      addRule: 'Add Rule',
      url: 'Destination Url',
      rule: 'Dynamic rule',
    },
    errors: {
      redirect:
        "The link you're trying to create is also a redirect, please use the final destination as the url instead.",
      invalidURL: 'The URL you have entered is invalid, please enter a valid URL.',
      blockedDomain: "The domain you're trying to use is not allowed.",
      unreachable: "The URL you're trying to use is unreachable.",
    },
  },
  redirect: {
    url: {
      input: {
        title: 'Destination URL',
        placeholder: 'Paste your URL',
      },
    },
    slug: {
      input: {
        title: 'Short link',
        placeholder: '',
      },
    },
    title: {
      input: {
        title: 'Title',
        placeholder: '',
      },
    },
    filter: {
      dates: {
        all: 'Lifetime',
        day7: 'Last 7 days',
        day14: 'Last 14 days',
        day30: 'Last 30 days',
        day90: 'Last 90 days',
      },
    },
    export: {
      title: 'CSV export',
      help: `Export ${variables.nbRedirects} redirects  ${
        variables.campaignName ? `from campaign ${variables.campaignName}` : ''
      } ${variables.subCampaignName ? `>${variables.subCampaignName}` : ''}`,
      list: {
        help: 'Select columns should be exported:',
      },
      button: {
        title: 'Export',
      },
      success: {
        help: 'You will be emailed a CSV of your redirect. At peak times this can take a few minutes',
        feedback: {
          title: 'Customer feedback:',
          help: 'Could you give us a feedback about your CSV export? Why do you need an export? What are you looking for? ',
          placeholder: 'Your feedback here...',
        },
      },
      feedback: {
        button: {
          title: 'Done',
        },
      },
    },
  },
  members: {
    delete: `Are you sure you want to remove
${variables.email} from "${variables.orgName}"?`,
    list: {
      name: 'Name',
      email: 'Email',
      signedUp: 'Successfully signed up',
      delete: 'Delete',
    },
  },
  links: {
    link: {
      favIcon: '',
      title: 'Title',
      shorten: 'Shortened URL',
      pixel: 'Pixels',
      clicks: 'Clicks',
      tags: 'Tags',
      source: 'UTM source',
      medium: 'UTM medium',
      campaign: 'UTM campaign',
      term: 'UTM term',
      content: 'UTM content',
      url: 'Destination URL',
      domain: 'Domain',
      slug: 'Slug',
      cookieConsent: 'GDPR consent',
      createdAt: 'Creation date',
    },
  },
  redirects: {
    empty: variables.campaignOrSubName
      ? `You don’t have any redirect for your folder ${variables.campaignOrSubName},<br/> Create a new redirect or Drag and drop one here from another folder`
      : 'You don’t have any redirect in your Dashboard,<br/> Create a new redirect or Drag and drop one here from another folder',
    unclassified: 'Unclassified links',
    allRedirects: 'All links',
    redirect: {
      favIcon: '',
      title: 'Title',
      shorten: 'Shortened URL',
      pixel: 'Pixels',
      clicks: 'Clicks',
      adsCost: 'Ads cost',
      tags: 'Tags',
      source: 'UTM source',
      medium: 'UTM medium',
      campaign: 'UTM campaign',
      term: 'UTM term',
      content: 'UTM content',
      url: 'Destination URL',
      domain: 'Domain',
      slug: 'Slug',
      cookieConsent: 'GDPR consent',
      createdAt: 'Creation date',
    },
  },
  settings: {
    profile: {
      language: {
        title: 'PixelMe Language Controls',
        languages: {
          en: 'English',
          zh: 'Chinese',
        },
      },
      reset: {
        title: 'Reset my cache',
        button: {
          title: 'Reset my cache and sign out',
        },
      },
    },
    trackingCode: {
      title: 'Conversion Tracking Code',
      htmlBlock: {
        title: 'HTML Tracking Code',
        subtitle:
          'Copy the tracking code below and paste it before the closing head tag in any HTML pages you wish to track events',
        isConnectedWidget: {
          connected: 'Connected',
          disconnected: 'Not connected',
        },
        buttonCTA: 'Copy',
        buttonOKNotification: 'Code copied to clipboard.',
        help: 'Need help ?',
        FAQ: 'Check our FAQ',
      },
      eventBlock: {
        title: 'Tracked events',
        titleLegend: '(Recommended)',
        subtitle:
          'Choose from our collection of pre-made events or create your own by editing the code sample yourself.',
        subtitleCustomEvents:
          'Track key actions visitors take on your site to see which events and customer paths lead to higher conversions.',
        subtitleCustomEventsVariables: "Don't forget to replace XXXX.",
        buttonCTA: 'Copy',
        buttonOKNotification: 'Code copied to clipboard.',
        instructions: {
          pickEvent: '1. Choose an event',
          copyEvent: '2. Copy paste the event script in your application',
        },
        help: 'Need help ?',
        FAQ: 'Check our FAQ',
      },
      modal: {
        title: 'Edit your event',
        displayName: 'Display name',
        displayNamePlaceholder: 'E. g. SignUp',
        archived: 'Display this event',
        save: 'Save',
      },
      myEventBlock: {
        title: 'My tracked events',
        titleLegend: '(Recommended)',
        subtitle: 'Press on an event to edit the name or to hide it from your dashboard.',
        eventName: 'Event name',
      },
    },
  },
  billing: {
    plans: {
      periodSwitcher: {
        monthly: 'Monthly',
        yearly: 'Yearly',
        yearlyBonus: ' SAVE UP TO -30%',
      },
      type: {
        marketer: 'For Marketer',
        amazon: 'For Amazon Seller',
      },
      planButtons: {
        current: 'Current plan',
        next: 'Next plan',
        reEnable: 'Re-enable plan',
        upgrade: 'Upgrade',
        downgrade: 'Downgrade',
        notActive: 'Not Active',
        previewPrice: 'Preview Price',
        trial: 'Start a free trial',
        businessTrial: 'Start a business free trial',
        switchMonthly: 'Switch to monthly',
        switchYearly: 'Switch to yearly',
        manageSubscription: 'Manage Subscription',
      },
      planDetails: {
        saleFlag: 'Best value',
        perMonth: ' /mo',
        billedYearly: ' (billed yearly)',
        upTo: 'Up to ',
        startingAt: 'Starting at ',
        visitors: 'clicks tracked',
        trackingScript: 'Tracking script',
        eventTracking: 'Event tracking',
        attribution: 'Attribution',
        billed: 'billed',
      },
      needMoreVisitors: 'Enterprise? ',
      needToChangeMyIBAN: 'Hi PixelMe team, I need to change my IBAN, can you help me? ',
      contactUs: 'Contact us !',
      cancel: 'Cancel my plan',
    },
  },
  signUp: {
    formSection: {
      subText: {
        'sign-up': '',
        'ltdf': 'URL shortener for savvy marketers',
        'appsumo': 'URL shortener for savvy marketers',
        'stackcommerce': 'URL shortener for savvy marketers',
      },
      cta: {
        'sign-up': 'Create your free PixelMe account now',
        'appsumo': 'Create your PixelMe account now',
        'ltdf': 'Create your PixelMe account now',
        'stackcommerce': 'Create your PixelMe account now',
      },
      signUpBtn: 'Sign up!',
    },
    specialOffer: {
      title: 'Welcome to the PixelMe special deal !',
      subTitle: 'Included in your Pro plan',
    },
  },
  accounts: {
    reporting: 'Reporting',
  },
  asinAudit: {
    title: 'ASIN Audit',
    editTitle: 'Edit Audit',
    newAudit: {
      title: 'New Audit',
      submit: 'Start new audit',
      submitAnother: 'Generate new audit',
      success: 'Audit successfully created',
      clearAll: 'Clear all',
    },
    auditResults: {
      title: 'Audit Results',
      back: 'Back to Audit Results',
    },
    auditHistory: {
      title: 'Audit History',
      back: 'Back to Audit History',
    },
    sellerId: {
      search: 'Audit by Seller ID',
      searchDescription: 'Enter a Seller ID that you would like us to audit.',
      placeholder: 'Enter a Seller ID',
      error: 'Seller ID must contain 12-14 alphanumeric characters',
      details: 'Audit details for Seller ID',
      requiredError: 'Please enter a valid Seller ID',
    },
    asin: {
      search: 'Audit by ASIN',
      searchDescription: `Enter up to ${variables.max} ASINs that you would like us to audit.`,
      placeholder: 'Enter ASINs',
      label: 'ASIN',
      error: 'ASIN number must contain 10 alphanumeric characters.',
      errorDescription: 'ASINs with the wrong format won’t be displayed in results.',
      detailsMultiple: 'Audit details for ASINs',
      detailsSingle: 'Audit details for ASIN',
      requiredError: 'Please enter at least one valid ASIN',
      competitorsDescription: 'You may add the competing ASINs',
      competitorsPlaceholder: 'Enter competing ASINs (Optional)',
      competitorsHelp: 'Comma Separated e.g. B073XSHTWR, B09Q7JY4Q3',
    },
    marketplace: {
      label: 'Marketplace',
    },
    contactInformation: {
      title: 'Contact Info',
      name: 'Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      emailExample: 'example@example.com',
      emailError: 'Please enter a valid email',
      phone: 'Phone Number',
      countryCode: 'Country Code',
      areaCode: 'Area Code',
      notes: 'Notes',
      notePlaceholder: 'Anything you find relevant to your profile',
      submit: 'Save contact info',
      success: 'Contact info successfully saved',
    },
    product: {
      title: 'Product',
      asin: 'ASIN',
      brand: 'Brand',
      price: 'Price',
      reviewCount: 'Review Count',
      reviewScore: 'Review Score',
      keywords: 'Keywords',
      profitScore: 'Profit Score',
      rankingScore: 'Ranking Score',
      strategy: 'Strategy',
      defaultTitle: '[Untitled Product]',
    },
    keyMetrics: {
      profitScore: 'Profit Score',
      rankingScore: 'Ranking Score',
      keywordProfitScore: 'Keyword Profit Score',
      amazonSearchVolume: 'Amazon Search Volume',
      googleSearchVolume: 'Google Search Volume',
      keywordRankingScore: 'Keyword Ranking Score',
      reviewScore: 'Review Score',
      reviewCount: 'Review Count',
      price: 'Price',
    },
    keywords: {
      title: 'Keyword',
      amazonSearchVolume: 'Amazon Search Volume',
      amazonCurrentRank: 'Amazon Current Rank',
      amazonCompetitorRank: 'Amazon Competitor Rank',
      googleSearchVolume: 'Google Search Volume',
      googleEstCostPerClick: 'Google Est. Cost Per Click',
      keywordProfitScore: 'Keyword Profit Score',
      keywordRankScore: 'Keyword Rank Score',
    },
    preview: {
      description:
        'Based on our research experience, we have uncovered some promising ASINs that we believe can drive up your organic rankings or open the door to a new profitable revenue stream through Off-Amazon campaigns.',
      strategyTooltip:
        'Profit Strategy = We predict that this product can advertise profitably using Google Ads.\n\nRanking Strategy = We predict that we can use Google Ads to increase organic rank on Amazon.',
      asvTooltip:
        'This is the amount of monthly searches on Amazon for the keywords that were selected for this strategy.',
      gsvTooltip:
        'This is the amount of monthly searches on Google for the keywords that were selected for this strategy.',
      oppKeywordsTooltip: 'The number of keywords we have identified for this strategy.',
      oppTooltip:
        'The confidence level of this opportunity. Very High means great and Medium means that this is an average opportunity.',
      impactScoreTooltip: 'The impact level we believe this Off-Amazon strategy will have on this ASIN',
      bookCall: 'Book a call with a consultant to review your keywords and recommended strategy',
      bookCallEmpty:
        'We were not able to find any ASIN opportunities for this seller ID. Please book a call with a consultant to initiate a new audit',
      noData: 'We were not able to find any ASIN opportunities for this store',
    },
    pageNotAvailable: 'This page is not available',
    bookCall: 'Book a call',
    topKeywords: 'Top Keywords',
    topCompetitors: 'Top Competitors',
    loading:
      'We are working on your audit.\nResults can take up to 5 minutes.\nYou can leave this page and come back later',
    auditCreated: 'Audit Created',
    auditUpdated: 'Audit Updated',
    asinsOrSellerIds: 'ASINs/Seller ID',
    status: 'Status',
    action: 'Action',
    impactScore: 'Impact Score',
    opportunityKeywords: 'Opportunity Keywords',
    missingId:
      'The results will appear after the new audit is generated,\nor one is opened from the Audit History tab.',
    failed: 'The audit failed to generate.\nPlease try again or contact Support.',
    noResults: 'No results found',
    share: {
      title: 'Share the results',
      inputLabel: 'Send via email',
      inputPlaceholder: 'Enter one or multiple emails',
      submit: 'Share',
      copyLink: 'Copy link',
      clipboard: 'Link copied to clipboard',
      sentTo: 'Email sent to',
      sentSuccess: `Emails sent to ${variables.numOfEmails} recipients`,
      pageTitle: 'PixelMe ASIN Audit',
    },
    strategies: {
      profit: 'Profit',
      rank: 'Rank',
    },
    auditImprovement: {
      editKeywords: 'Edit Top Keywords',
      searchKeywords: 'Search Keywords',
      addKeywords: 'Add my own Keywords',
      keywordsUpdated: 'Keywords updated',
      newKeywordsDescription: 'Add your own keywords in each line',
      addToTopKeywords: 'Add to Top Keywords',
    },
  },
  productTags: {
    title: 'Tags',
    description: 'Enter up to five product tags. A good candidate for a tag can be a product brand or category.',
    placeholder: 'Filter by Tags',
  },
  facebook: {
    campaignObjective: {
      appPromotion: 'App Promotion',
      awareness: 'Awareness',
      engagement: 'Engagement',
      leads: 'Leads',
      sales: 'Sales',
      traffic: 'Traffic',
      placeholder: 'Select a campaign objective',
      title: 'Campaign Objective',
    },
    performanceGoals: {
      offsiteConversions: 'Maximize Number of Conversions',
      value: 'Maximize Value of Conversions',
      linkClicks: 'Maximize Number of Link Clicks',
      placeholder: 'Select a performace goal',
      title: 'Performance Goal',
    },
  },
  facebookAds: {
    title: 'Facebook Ads',
    reconnect: 'Reconnect Facebook Ads',
    connect: 'Connect Facebook Ads',
    accounts: {
      title: 'Facebook Accounts',
      noName: 'No Descriptive Name',
      description: 'Select one of your Facebook accounts in the list below.',
      connect: 'Connect your account',
    },
  },
  googleAds: {
    title: 'Google Ads',
    reconnect: 'Reconnect Google Ads',
    connect: 'Connect Google Ads',
    accounts: {
      title: 'Google Ads Accounts',
      description: 'Select one of your Google Ads accounts in the list below.',
      connect: 'Connect your account',
    },
    editCampaign: 'Edit on Google Ads',
  },
  adsProvider: {
    googleAdsPrompt: 'Don’t have a Google Ads account yet?',
    loginSuccess: {
      title: 'You are all set!',
      description: `Create campaigns on ${variables.provider} and track the results of all your campaigns with Amazon Attribution.`,
      createCampaign: 'Create your first campaign',
    },
  },
  amazonOnboarding: {
    title: 'Launch ads from PixelMe',
    description:
      'Create campaigns on Google, Facebook, or Tiktok and track the results of all your campaigns with Amazon Attribution.',
    createCampaign: 'Create Amazon Attribution Campaign',
    additionalInfo: 'You can also create custom attribution links.',
    additionalInstructions: 'Click above to get started',
  },
  adsProviderReLoginModal: {
    migration: {
      title: 'Reconnect Google Ads Account',
      description:
        'We just completed a number of upgrades to the Google Ads API. This makes our Google Ads services more reliable and improves the speed of our interactions. Please reconnect your Google Ads Account below in order to upgrade your connection',
    },
    login: {
      title: 'Ads account was disconnected',
      errorTitle: 'Your ads account was disconnected. Please reconnect it.',
      errorDescription: 'If the problem persists, please contact us.',
    },
  },
  variations: {
    keywordRankTrendTitle: 'Keyword Rank Trend',
    keywordRankTrendTooltip: `The moving average of the first ${variables.avgLength} ranks compared to the last ${variables.avgLength} ranks from the selected date range.`,
  },
  global: {
    dates: {
      all: 'Lifetime',
      day7: 'Last 7 Days',
      day14: 'Last 14 Days',
      day30: 'Last 30 Days',
      day60: 'Last 60 Days',
      day90: 'Last 90 Days',
      day365: 'Last 365 Days',
      week1: 'Last Week',
      month1: 'Last Month',
      month3: 'Last 3 Months',
      month12: 'Last 12 Months',
      ALL: 'Lifetime',
      LAST_7D: 'Last 7 Days',
      LAST_30D: 'Last 30 Days',
      LAST_90D: 'Last 90 Days',
      allPixelMe: 'PixelMe Lifetime',
    },
    back: 'Back',
    beta: 'Beta',
    add: 'Add',
    ok: 'OK',
  },
  locales: {
    profile: {
      accountDetails: 'Account details',
      profileDetails: 'Profile details',
      cancel: 'Cancel',
      updateAccount: 'Update account',
      edit: 'Edit',
    },
    teamMembers: {
      owner: 'Owner',
      addMember: 'Add a new team member',
      myTeamMembers: 'My team members',
    },
    dash: {
      info: 'Select a product below to create a campaign',
      newProduct: 'New Product',
      searchForAnything: 'Search for anything',
      campaignStatus: 'Campaign Status',
      allMarketingChannels: 'All Marketing Channels',
      allCampaigns: 'All Campaigns',
      noProducts: 'No products found',
      infoAttribution:
        "If you don't have attribution data, this may be due to hyper targeted audience and low audience size.",
      noCampaignsError: 'No Campaigns available for this product',
      deleteConfirm: 'Are you sure to want to delete?',
      campaignRunning: 'Campaign Running',
      adDataReceived: 'Ad Data Received',
      amazonDataReceived: 'Amazon Data Received',
      warning: 'WARNING: Data is out of date.',
      contactSupport: 'Contact Support for more information.',
    },
    headers: {
      teamMembers: 'Team Members',
      profile: 'Profile',
      lifetimeCoupon: 'Lifetime Coupon',
      usage: 'Usage',
      invoices: 'Invoices',
      subscription: 'Subscription',
      cc: 'Credit Card Details',
      sepa: 'SEPA Details',
      links: 'Links',
      linkAnalytics: 'Link Analytics',
      bulkImport: 'Bulk Import',
      settings: 'Settings',
      lButton: 'Link Button',
      appearance: 'Appearance',
      analytics: 'Analytics',
      aa: 'Amazon Attribution',
      apiAccounts: 'API Accounts',
      products: 'Products',
      oneLink: 'One Link',
      linkManager: 'Link Manager',
      aaCampaigns: 'Amazon Attribution Campaigns',
      dashboard: 'Dashboard',
      overview: 'Overview',
      alerts: 'Alerts',
    },
    columns: {
      createdAt: 'Created At',
      amazonRanking: 'Amazon Ranking',
      firstPositionEstBid: 'First Position Est. Bid',
      firstPageEstBid: 'First Page Est. Bid',
      avgBrandReferralBonus: 'Avg. Brand Referral Bonus',
      acos: 'ACOS',
      roas: 'ROAS',
      netMargin: 'Net Margin',
      revenue: 'Revenue',
      purchases: 'Purchases',
      addToCart: 'Add to Cart',
      attributedCpc: 'Attributed CPC',
      attributedAdCost: 'Attributed Ad Cost',
      attributedClicks: 'Attributed Clicks',
      impressions: 'Impressions',
      clicks: 'Clicks',
      attributedImpressions: 'Attributed Impressions',
      status: 'Status',
      matchType: 'Match Type',
      keyword: 'Keyword',
      adGroupName: 'Ad Group Name',
      totalAdCost: 'Total Ad Cost',
      providerStatus: 'Provider Status',
      keywords: 'Keywords',
      location: 'Location',
      cpc: 'CPC',
      adSpend: 'Ad Spend',
      campaignName: 'Campaign Name',
      productName: 'Product Name',
      productASIN: 'Product ASIN',
      amazonSearchVolume: 'Amazon Search Volume',
      amazonCurrentRank: 'Amazon Current Rank',
      googleSearchVolume: 'Google Search Volume',
      googleEstCostPerClick: 'Google Est. Cost Per Click',
      liveImpressions: 'Live Impressions',
      liveClicks: 'Live Clicks',
      liveAdCost: 'Live Ad Cost',
      liveCPC: 'Live CPC',
      rawData: 'Raw Data',
      conversionRate: 'Conversion Rate',
      addToCartRate: 'Add to Cart Rate',
      clickThroughRate: 'Click Through Rate',
      newToBrandPurchases: 'New to Brand Purchases',
      newToBrandRevenue: 'New to Brand Revenue',
      dataProviders: 'Data Providers',
      campaigns: 'Campaigns',
      viewLastUpdate: 'View Last Update',
      accountName: 'Account Name',
      alertName: 'Alert Name',
      resourceName: 'Resource Name',
      actions: 'Actions',
      annualizedPurchaseFrequency: 'Annualized Purchase Frequency',
      annualizedAcos: 'Annualized ACOS',
      annualizedRoas: 'Annualized ROAS',
      annualizedRevenue: 'Annualized Revenue',
    },
    billing: {
      amazSeller: 'For Amazon Sellers',
      attributionLinksOnly: 'Attribution Links Only',
      forMarketers: 'For Marketers',
      talkToUs: 'Talk to us',
      ccm: 'Campaign Creation And Management',
      googleAdCamp: 'Google Ad Campaigns',
      facebookAdCamp: 'Facebook Ad Campaigns',
      tiktokAdCamp: 'TikTok Ad Campaigns',
      retargetingPixels: 'Retargeting Pixels',
      amazonProducts: 'Amazon Products',
      microLandingPages: 'Micro Landing Pages',
      organicRankTracking: 'Organic Rank Tracking',
      keywordLevelConversions: 'Keyword Level Conversions',
      dedicatedAccountManager: 'Dedicated Account Manager',
      dedicatedPhoneSupport: 'Dedicated Phone Support',
      customCreativeServices: 'Custom Creative Services',
      multipleAmazonAccounts: 'Multiple Amazon Accounts',
      trackedClicksPerMonth: 'Tracked Clicks Per Month',
      customDomain: 'Custom Domain',
      deepLink: 'Deep Link',
      dedicatedCustomerSupport: 'Dedicated Customer Support',
      seats: 'Seats',
      customPrice: 'Custom Price',
      noAdsError: 'No Ads in this plan. To create Google, Facebook, Tiktok Ads upgrade to Pro Plan',
      contact: 'Contact us',
      unlimited: 'Unlimited',
      overview: 'Overview',
      plan: 'Plan',
      created: 'Created',
      currentPeriod: 'Current Period',
      nextInvoice: 'Next Invoice',
      monthlyClicksUsage: 'Monthly Clicks Usage',
      included: 'Included',
      used: 'Used',
      usageReset: 'Usage Reset',
      limits: 'Limits',
      brandedLinks: 'Branded Links',
      customDomains: 'Custom Domains',
      teamMembers: 'Team Members',
      workspaces: 'Workspaces',
      notIncluded: 'Not included in your plan',
      extraCost: 'Additional Cost',
    },
    oneLink: {
      createMyPage: 'Create My Page',
      chooseUrl: 'Choose the URL of your micro landing page',
      info: 'PixelMe One-link is the best way to build a micro-website in a few minutes without coding',
      welcome: 'Welcome to PixelMe One Link!',
    },
    accounts: {
      accountSwitcher: 'Account Switcher',
      subscriptionStatus: 'Subscription Status',
      googleAdsIds: 'Google Ads IDs',
      activeAndTotalCampaigns: 'Active/Total Campaigns',
      startDate: 'Start Date',
      dailyBudget: 'Daily Budget',
      cost: 'Cost',
      averageCpc: 'Average CPC',
      addToCarts: 'Add to Carts',
      conversions: 'Conversions',
      conversionRate: 'Conversion Rate',
      unitsSold: 'Units Sold',
      acosRange: 'ACOS Range',
      searchForAccountsByName: 'Search for accounts by name',
      impressions: 'Impressions',
      clicks: 'Clicks',
      revenue: 'Revenue',
      roas: 'ROAS',
      acos: 'ACOS',
      brandReferralBonus: 'Brand Referral Bonus',
      addToCartRate: 'Add to Cart Rate',
      clickThroughRate: 'Click Through Rate',
    },
    campaigns: {
      newLink: 'New Link',
      newCampaign: 'New Campaign',
      importCampaigns: 'Import Campaign(s)',
      campaignError: 'Current Plan does not support campaigns, Upgrade to Pro Plan to enable campaigns',
    },
    variations: {
      info: "The data displayed here is limited by Amazon's shopper privacy policy, which only shows purchase data for keywords with more than 10 clicks.",
      infoTwo: 'Keyword with less than 10 clicks will be display as zero until the click threshold is reached.',
    },
  },
});
