import moment from 'moment';

import { DATE_FORMAT, LIFETIME_START_DATE } from 'selectors/ranges.selector';

import { FIXED_DECIMAL_METRICS, MONETARY_METRICS, PERCENT_METRICS, fixedDecimalFormatter } from '../constants';
import { GRAPH_TOOLTIP_WIDTH } from '../Style';

export const GRAPH_HEIGHT = 300;
export const GRAPH_START: number = GRAPH_TOOLTIP_WIDTH + 60;
export const CHART_CLOSED_HEIGHT = 46;
export const METRICS_CHART_CONTAINER_HEIGHT: number = GRAPH_HEIGHT + 131;
export const RANKING_CHART_CONTAINER_HEIGHT: number = GRAPH_HEIGHT + 112;

export const CACHE = {
  PREFIX: 'chart-visible-',
  SHOW: 'true',
  HIDE: 'false',
};
const getCache = (key) => localStorage.getItem(`${CACHE.PREFIX}${key}`);
const setCache = (key, value) => localStorage.setItem(`${CACHE.PREFIX}${key}`, value);

export const getCacheVisible = (key: string): boolean => getCache(key) === CACHE.SHOW;

export const defaultGraphSettings = {
  chart: {
    marginLeft: GRAPH_START,
    spacingTop: 20,
    spacingBottom: 20,
    spacingRight: 16,
    backgroundColor: '#fff',
    height: GRAPH_HEIGHT,
  },
  credits: {
    enabled: false,
  },
  lang: {
    // Metrics contain zeroes for dates with no data so this label only shows before fetching is complete
    noData: 'Loading...',
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    crosshair: true,
    type: 'datetime',
    dateTimeLabelFormats: {
      month: '%e. %b',
      year: '%b',
    },
  },
  yAxis: [
    {
      visible: true,
      labels: { enabled: false },
      title: { text: '' },
    },
  ],
  title: {
    text: '',
  },
  tooltip: {
    shared: true,
    useHTML: true,
    positioner: (): { x: number, y: number } => ({ x: 0, y: 0 }),
    borderWidth: 0,
    shadow: false,
    backgroundColor: 'transparent',
    padding: 16,
    headerFormat: '<div class="tooltip-header">{point.key}</div>',
    xDateFormat: '%a, %b %d, %Y',
    hideDelay: 0,
  },
  series: [],
  plotOptions: {
    series: {
      states: {
        inactive: {
          opacity: 1,
        },
        hover: {
          halo: {
            size: 8,
          },
        },
      },
      marker: {
        enabled: true,
        symbol: 'circle',
        radius: 2,
      },
      lineWidth: 1,
      events: {
        hide() {
          setCache(this.name, CACHE.HIDE);
        },
        show() {
          setCache(this.name, CACHE.SHOW);
        },
      },
    },
  },
};

/**
 * Empty line is a line that has data for every possible date
 * and is never visible to the user, but helps us sync cursor between graphs
 */
export const getEmptyLine = (startDate: moment$Moment, endDate: moment$Moment) => {
  const emptySeries = [];

  const currentDate = startDate.clone();

  while (currentDate.isSameOrBefore(endDate)) {
    emptySeries.push([currentDate.valueOf(), 0]);
    currentDate.add(1, 'd');
  }

  return {
    name: 'pxlme::empty',
    showInLegend: false,
    lineWidth: 1,
    data: emptySeries,
    visible: true,
    yAxis: 0,
    marker: {
      enabled: false,
    },
    enableMouseTracking: false,
    color: '#e6e6e6',
    states: {
      hover: {
        lineWidth: 1,
        lineWidthPlus: 0,
        halo: {
          size: 0,
        },
      },
    },
  };
};

export const roundTo100 = (value) => Math.round(value / 100) * 100;

const getTooltipRow = ({ key, value, color }) => `
  <div class="tooltip-row">
    <div class="tooltip-bullet" style="color: ${color}">●</div>
    <div class="tooltip-key">${key}</div>
    <div class="tooltip-value">${value}</div>
  </div>
`;

export const MetricsPointFormatter = function (currencySymbol = '$') {
  // Hide line with zeros
  if (this.series.index <= 0) {
    return '';
  }

  let { name } = this.series;

  const liveDate = moment().utc().startOf('day').subtract(2, 'd');

  if (this.x >= liveDate && this.series.index < 8) {
    name += ' (Non-attributed)';
  }

  let value = this.y;

  if (MONETARY_METRICS.includes(this.series.name)) {
    value = `${currencySymbol}${value.toFixed(2)}`;
  } else if (PERCENT_METRICS.includes(this.series.name)) {
    value = `${value.toFixed(0)}%`;
  } else if (FIXED_DECIMAL_METRICS.includes(this.series.name)) {
    value = fixedDecimalFormatter(value);
  }

  return getTooltipRow({
    key: name,
    value,
    color: this.series.color,
  });
};

export const RankingPointFormatter = function () {
  // Hide line with zeros
  if (this.series.index <= 0) {
    return '';
  }

  return getTooltipRow({
    key: this.series.name,
    value: this.y,
    color: this.series.color,
  });
};

export const getFirstDateIndex = (metrics: GetProductMetricsResponse): number => {
  for (let i = 0; i < metrics.clicks.length; i += 1) {
    if (
      metrics.clicks[i].value ||
      metrics.totalAddToCarts[i].value ||
      metrics.impressions[i].value ||
      metrics.totalPurchases[i].value ||
      metrics.adCost[i].value ||
      metrics.totalRevenue[i].value ||
      metrics.acos[i].value ||
      metrics.roas[i].value ||
      metrics.clicks[i].value ||
      metrics.bsr.find((x) => x.categoryName && x.metrics[i].value) ||
      (metrics.organicSales !== undefined && metrics.organicSales[i].value) ||
      (metrics.tacos !== undefined && metrics.tacos[i].value) ||
      metrics.ranking.find((x) => x.metrics[i].value)
    ) {
      return i;
    }
  }

  return 0;
};

export const isLifetimeSelected = (start: moment$Moment, end: moment$Moment): boolean =>
  start.format(DATE_FORMAT) === LIFETIME_START_DATE && end.format(DATE_FORMAT) === moment().utc().format(DATE_FORMAT);
