import React, { Component } from 'react';

import { Statistic } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Actions } from 'actions';
import Checkbox from 'components/common/Checkbox';
import { Favicon } from 'components/common/FavIcon';
import { Analytics, Copy, Delete, Edit, Repeat } from 'components/common/icons';
import { FirstBullet } from 'components/common/icons/FirstBullet';
import { QrCode } from 'components/common/icons/QrCode';
import PixelsList from 'components/common/PixelsList';
import TagsList from 'components/common/TagsList';
import { NAValue } from 'components/pages/AdsProviders/common/NAValue';
import { roasFormatter } from 'components/pages/AdsProviders/Dashboard/constants';
import { DATE_FORMAT } from 'consts/consts';
import { getGridTemplate } from 'lib/columns';
import { s } from 'lib/safe';
import { getIntlStrings } from 'locales/i18n/localeHelpers';
import { strings } from 'locales/strings';
import { getRedirectsSelectedForCampaigns } from 'selectors/redirects.selector';
import { selectProductProvider, selectCurrentAccount } from 'stores/accounts';

import boxImage from './DragRedirectsImage';
import { RedirectsListLineCell } from './RedirectsListLineCell';
import {
  Campaign,
  Check,
  Clicks,
  Content,
  CookieConsent,
  CreatedAt,
  Domain,
  Drag,
  DropIcon,
  IconInsideButton,
  Medium,
  PixelCol,
  Shorten,
  Slug,
  Source,
  Tags,
  Term,
  Title,
  Url,
} from './RedirectsListLineElements';
import { RedirectsListLineMenu, RedirectsListLineMenuSlideAnimation } from './RedirectsListLineMenu';

type internalPropsT = {
  account: accountT,
  redirect: redirectT,
  selected: boolean,
};
type internalActionsT = { toggleRedirect: Function };
type internalStateT = {};
const defaultState: internalStateT = {};

class AbstractRedirectsListLineNoStyle extends Component<internalPropsT & internalActionsT, internalStateT> {
  state = { ...defaultState };

  shouldComponentUpdate(nextProps, nextState) {
    const { redirect, config, selected } = this.props;

    return (
      !_.isEqual(redirect, nextProps.redirect) ||
      !_.isEqual(config, nextProps.config) ||
      !_.isEqual(selected, nextProps.selected) ||
      !_.isEqual(selected, nextProps.selectedRedirects)
    );
  }

  render() {
    const {
      config,
      account,
      activeAmazonProfile,
      redirect,
      connectDragSource,
      className,
      selected,
      isOver,
      toggleRedirect,
      handleEditRedirect,
      handleDuplicateRedirect,
      handleDeleteRedirect,
      limitations,
    } = this.props;
    const img = new Image();

    img.onload = () => {
      this.props.connectDragPreview && this.props.connectDragPreview(img);
    };
    img.src = boxImage(this.props.selectedRedirects.length ? this.props.selectedRedirects.length : 1);
    console.log(
      '%c render front/src/components/pages/AudienceBuilder/Campaigns/campaigns/RedirectsListLine.jsx',
      'color: yellow',
    );

    return connectDragSource(
      <span
        className={className}
        style={{
          gridTemplateColumns: getGridTemplate(config.selectedColumns.length + 3),
        }}
      >
        {!!isOver && <DropIcon />}
        <Drag forceUpdate>
          <FirstBullet />
        </Drag>
        <Check>
          <Checkbox checked={selected} handleClick={() => toggleRedirect(redirect)} />
        </Check>
        <Title config={config} textToCopy={redirect.title}>
          <Favicon
            referrerPolicy="no-referrer"
            src={`${s(s(window).env).REACT_APP_ICON_URL}/?url=${encodeURIComponent(redirect.url)}&size=32`}
          />
          <span title={redirect.title}> {redirect.title}</span>
        </Title>
        <Url config={config} textToCopy={redirect.trackUrl}>
          {' '}
          <span title={redirect.trackUrl}>{redirect.trackUrl}</span>
        </Url>
        <Clicks config={config}>
          <Link to={`/${account.id}/ab/analytics/l/${redirect.id}`}>
            <ClicksVal clicks={redirect.clicks} />
          </Link>
        </Clicks>
        <Shorten
          color={redirect.productProviderType === 'AMAZON' ? 'rgb(248,165,27) !important' : undefined}
          config={config}
          textToCopy={redirect.shorten}
        >
          <span title={redirect.shorten}>{redirect.shorten}</span>
        </Shorten>
        <PixelCol config={config}>
          <PixelsList pixels={redirect.pixels} label="" />
        </PixelCol>
        <Tags config={config}>{redirect.tags && <TagsList tags={redirect.tags} />}</Tags>
        <Source config={config}>{redirect.utms && redirect.utms.source} </Source>
        <Medium config={config}>{redirect.utms && redirect.utms.medium} </Medium>
        <Term config={config}>{redirect.utms && redirect.utms.term} </Term>
        <Content config={config}>{redirect.utms && redirect.utms.content} </Content>
        <Campaign config={config}>{redirect.utms && redirect.utms.campaign} </Campaign>
        <CookieConsent config={config}>
          {redirect.cookieConsent ? getIntlStrings('yes') : getIntlStrings('no')}
        </CookieConsent>
        <CreatedAt config={config}>{moment(redirect.createdAt).format(DATE_FORMAT)}</CreatedAt>

        <Domain config={config} textToCopy={redirect.domain}>
          {redirect.domain}
        </Domain>
        <Slug config={config} textToCopy={redirect.slug}>
          {redirect.slug}
        </Slug>

        <RedirectsListLineMenu>
          <RedirectsListLineMenuSlideAnimation>
            <IconInsideButton>
              <RedirectsListLineCell forceDisplay textToCopy={redirect.shorten}>
                <Copy />
              </RedirectsListLineCell>
            </IconInsideButton>
            {redirect.shorten && (
              <IconInsideButton>
                <a
                  href={`${s(s(window).env).REACT_APP_QRCODE_URL}/?data=${redirect.shorten}&size=300`}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <QrCode />
                </a>
              </IconInsideButton>
            )}
            <IconInsideButton>
              <Link to={`/${account.id}/ab/analytics/l/${redirect.id}`}>
                <Analytics />
              </Link>
            </IconInsideButton>
            <IconInsideButton>
              <Edit onClick={() => handleEditRedirect(redirect)} />
            </IconInsideButton>
            <IconInsideButton>
              <Repeat onClick={() => handleDuplicateRedirect(redirect)} />
            </IconInsideButton>
            <IconInsideButton>
              <Delete onClick={() => handleDeleteRedirect(redirect)} />
            </IconInsideButton>
          </RedirectsListLineMenuSlideAnimation>
        </RedirectsListLineMenu>
      </span>,
    );
  }
}

// noinspection JSUnusedLocalSymbols
const stateToProps = (state: stateT) => ({
  activeAmazonProfile: selectProductProvider(state),
  selectedRedirects: getRedirectsSelectedForCampaigns(state),
  account: selectCurrentAccount(state),
  limitations: state.accounts.limitations,
  config: state.front.audienceBuilder.campaigns.columnsConfig,
});

// noinspection JSUnusedGlobalSymbols
const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  toggleRedirect: (redirect) =>
    dispatch(
      Actions.front.audienceBuilder.campaigns.toggleRedirect.request({
        redirect,
      }),
    ),
  handleEditRedirect: (redirect) => dispatch(Actions.front.audienceBuilder.redirect.edit.show.request({ redirect })),
  handleDeleteRedirect: (redirect) =>
    dispatch(Actions.front.audienceBuilder.redirect.delete.show.request({ redirect })),
  handleDuplicateRedirect: (redirect) =>
    dispatch(
      Actions.front.audienceBuilder.redirect.duplicate.show.request({
        redirect,
      }),
    ),
});

const reduxWrapper = connect(stateToProps, dispatchToProps, null);

const RedirectsListLine = styled(reduxWrapper(AbstractRedirectsListLineNoStyle)).withConfig({
  displayName: 'RedirectsListLine',
})`
  :hover {
    background-color: #f4f9fd;
  }
  :hover > .${RedirectsListLineMenu.styledComponentId} {
    opacity: 1;
  }
  :hover .${RedirectsListLineMenuSlideAnimation.styledComponentId} {
    margin-left: 0px;
  }
  :hover .${Drag.styledComponentId} {
    opacity: 1;
  }
  .${Drag.styledComponentId} {
    opacity: ${(props) => (props.selected ? 1 : 0.1)};
  }
  display: grid;
  grid-auto-rows: 50px;
  column-gap: 10px;
  grid-auto-columns: min-content;
  cursor: pointer;
  width: fit-content;
  min-width: 100%;
  border-bottom: var(--border-size) solid #e5e5e5;
`;

RedirectsListLine.TYPE = 'RedirectsListLine';

export default RedirectsListLine;

export const ClicksValContainer = styled.div.withConfig({
  displayName: 'ClicksValContainer',
})`
  display: flex;
  align-items: flex-end;
  text-align: right;
  place-content: flex-end;
  text-decoration: none !important;
  color: var(--text-color);
  > svg {
    margin-right: 0.2rem;
  }
  > svg > rect {
    fill: var(--text-muted);
  }
`;

export const CenteredValContainer = styled.div.withConfig({
  displayName: 'CenteredValContainer',
})`
  text-align: center;
  text-decoration: none !important;
  color: var(--text-color);
  > svg {
    margin-right: 0.2rem;
  }
  > svg > rect {
    fill: var(--text-muted);
  }
`;

export const AmazonClicksValContainer = styled.div.withConfig({
  displayName: 'ClicksValContainer',
})`
  display: flex;
  align-items: center;
  text-decoration: none !important;
  color: rgb(248, 165, 27) !important;
`;

export function ROASValue(props: { roas: any }) {
  const { roas } = props;

  return (
    <Statistic
      title=""
      precision={0}
      valueStyle={{
        color: roas >= 0 && roas < 100 ? 'inherit' : '#0F993E',
        fontSize: '14px',
        textAlign: 'right',
        justifyContent: 'flex-end',
      }}
      value={roasFormatter(roas, false)}
      suffix="%"
    />
  );
}

export function ACOSValue(props: { acos: any | ((x: number) => number) }) {
  let { acos } = props;

  if (acos < 0) {
    acos = 0;
  }

  return (
    <Statistic
      title=""
      precision={0}
      valueStyle={{
        color: acos > 100 ? 'inherit' : '#0F993E',
        fontSize: '14px',
        textAlign: 'right',
        justifyContent: 'flex-end',
      }}
      value={acos}
      suffix="%"
    />
  );
}

export function CurrencyValue(props: { amount: any, currency: string, suffix?: string, unit?: number, style?: any }) {
  if (props.amount < 0) {
    return <NAValue />;
  }

  const unit = props.unit || 1;

  return (
    <Statistic
      title=""
      valueStyle={{ textAlign: 'right', justifyContent: 'flex-end', ...(props.style || {}) }}
      suffix={props.suffix}
      precision={2}
      value={(props.amount || 0) / unit}
      prefix={props.currency}
    />
  );
}

export function AmazonStatsVal({ clicks }: { clicks: int }) {
  if (clicks < 0) {
    return <NAValue />;
  }

  return <AmazonClicksValContainer> {`${numeral(clicks).format('0,000')}`}</AmazonClicksValContainer>;
}

export function ClicksVal({ clicks }: { clicks: int }) {
  if (clicks < 0) {
    return <NAValue />;
  }

  return <ClicksValContainer>{`${numeral(clicks).format('0,000')}`}</ClicksValContainer>;
}

export function NumberVal({ clicks }: { clicks: int }) {
  if (clicks < 0) {
    return <NAValue />;
  }

  return <ClicksValContainer>{`${numeral(clicks).format('0,000')}`}</ClicksValContainer>;
}

export function CenteredNumberVal({ clicks }: { clicks: int }) {
  if (clicks < 0) {
    return <NAValue />;
  }

  return <CenteredValContainer>{`${numeral(clicks).format('0,000')}`}</CenteredValContainer>;
}
